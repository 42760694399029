<template>
  <div  class="container">
   <div class="container-main">
       <div class="ftco-section center" id="convert">
        <h1 class=title> {{$t('Super Fast YouTube to MP3/MP4 Converter')}}</h1>
        <p>{{$t('Best free tool to download videos from YouTube')}}</p>
        <div id=search-form class="search-form relative">
            <input @keyup.enter="getVideos()" :class="{'text-right':$i18n.locale == 'ar'}"  v-model="searchValue" id=s_input type=search name=q class="search__input" aria-label=Search
                :placeholder="$t('Search or paste Youtube link here')">
            <button v-on:click="getVideos()" id="start" class="btn-red" type="button">
             <div class="d-flex">
                 <span>{{$t('Start')}}</span>
             <span class="mx-2"><i class="fa fa-arrow-right" aria-hidden="true"></i></span>
             </div>
            </button>
        </div>
        <div v-if="processing" id=loader-wrapper>
            <img alt="loading" src="/assets/img/loading.gif">
        </div>
        <div v-if="finished && !processing" id="search-result">
            <div class="col-md-12">
                <div class="row">
                    <div v-for="video in searchResults.items" :key="video.id"
                     class="col-md-4">
                         <VideoElement :video="video"></VideoElement>
                    </div>
                </div>
            </div>
        </div>
    </div>
   </div>
    <Content></Content>
  </div>
 
</template>
<script>
    const axios = require('axios')
    const API_URL = location.origin +'/'
    import VideoElement from "./VideoElement.vue"; 
    import Content from "./Content.vue"; 

    export default {
        name: "Search",
        mounted(){
             this.API_URL = this.ENVIRONNEMENT === "prod" ? API_URL : this.API_ROOT_DEV
             // get videos if search url request exist
            const params = this.$route.params;
            if(params.q){
                this.searchValue = params.q;
                this.getVideos();
            }
        },
        data() {
            return {
                processing: false,
                finished: false,
                searchValue: '',
                searchResults: {},
                iframs: {},
                API_URL:''
            }
        },
        components: {VideoElement,Content},
        methods: {
            getVideos() {
               const searchValue = this.searchValue;
               if(searchValue.length){
                this.processing = true;
                if(!this.$route.params.q)
                {
                    this.$router.push({name:'search',params:{lang:this.$i18n.locale,q:searchValue}})
                }
                const input = encodeURIComponent(this.searchValue);
                axios.get(this.API_URL + 'get-videos?search=' + input).then(res => {
                    this.searchResults = res.data;
                    this.processing = false;
                    this.finished = true;
                })
                }
            },
        },
    }
</script>
<style lang="scss">

</style>