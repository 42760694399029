<template>
    <header id=navbar>
    <div class="container">
        <div class="topnav d-flex justify-content-between" id=myTopnav>
            <div  @click="home()" role="button" class="nav-logo d-flex">
                <img width="46" height="46" alt="logo" src="/assets/img/logo.png">
                <a href="#" class="navbar-brand waves-effect p-0 mx-2"> {{WEBSITE_NAME}}</a>
             </div>
            <div @click="openNav()"  class="nav-item" itemscope itemtype=http://www.schema.org/SiteNavigationElement>
                 <div class="dropdown">
                    <div class="dropbtn dropdown-title text-dark"> {{locales[$i18n.locale]}}
                      <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    </div>
               <div class="dropdown-content"> 
                <a  @click="setLang(lang)" v-for="(lang, i) in Object.keys(locales)" :key="`locale-${i}`" class="dropdown-item"  href="#">{{locales[lang]}}</a> 
                 </div> 
                </div>
            </div>
        </div>
    </div>
</header>
</template>

<script>
import * as $ from 'jquery'
export default {
  name: 'Header',
  data() {
      return {
          locales: {
            "en" :"English",
            "ar" :"العربية",
            "tr" :"Türkçe",
            "hi" :"हिन्दी / Hindī",
            "fr" :"Français",
            "es" :"Español",
            "id" :"Indonesian",
            }
      }
  },
  methods: {
      openNav(){
            $(".topnav").toggleClass("responsive");
      },
      setLang(lang){
          this.$i18n.locale = lang;
          localStorage.setItem('lang',lang);
          this.$router.push({name:'language',params:{lang:lang}});
      },
      home(){
          document.location.assign('http://'+location.host);
      },

  },
  mounted() {
      const params = this.$route.params;
      if(params.lang && this.locales[params.lang]){
        this.$i18n.locale = params.lang;
      }
  },
}
</script>

<style lang="scss" scoped>

</style>