export default {
  "Super Fast YouTube to MP3/MP4 Converter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YouTube वीडियो मुफ्त में डाउनलोड करें"])},
  "Best free tool to download videos from YouTube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YouTube से वीडियो डाउनलोड करने का सबसे अच्छा मुफ्त टूल"])},
  "Search or paste Youtube link here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube लिंक यहां खोजें या पेस्ट करें"])},
  "Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुरू"])},
  "Download Video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुरू"])},
  "The best online YouTube video downloader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सबसे अच्छा ऑनलाइन YouTube वीडियो डाउनलोडर"])},
  "Free tool to help you download video from Youtube to your computer for free. In addition, it supports converting to many different formats and qualities for download.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यूट्यूब से आपके कंप्यूटर पर मुफ्त में वीडियो डाउनलोड करने में आपकी मदद करने के लिए नि:शुल्क टूल। इसके अलावा, यह डाउनलोड के लिए कई अलग-अलग स्वरूपों और गुणों में कनवर्ट करने का समर्थन करता है।"])},
  "Quick and easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जल्द और आसान"])},
  "Our Youtube video downloader helps you quickly search and download any Youtube video to formats like: MP4, WEBM, M4A ...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमारा Youtube वीडियो डाउनलोडर आपको किसी भी Youtube वीडियो को शीघ्रता से खोजने और डाउनलोड करने में मदद करता है जैसे: MP4, WEBM, M4A ..."])},
  "Unlimited YouTube downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असीमित YouTube डाउनलोड"])},
  "Support downloading and converting unlimited Youtube videos with the best transfer speed up to 1GB/s.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1GB/s तक की सर्वोत्तम स्थानांतरण गति के साथ असीमित Youtube वीडियो डाउनलोड करने और परिवर्तित करने में सहायता करें।"])},
  "Safety and Security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बचाव और सुरक्षा"])},
  "The converted and downloaded files are kept the same quality and content as YouTube's.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनवर्ट की गई और डाउनलोड की गई फ़ाइलों को YouTube के समान गुणवत्ता और सामग्री के रूप में रखा जाता है।"])},
  "Support all devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी उपकरणों का समर्थन करें"])},
  "a website-based Youtube video downloader that works well on all platforms such as Windows, Mac or Linux, Android, iPhone ... without downloading or installing any software.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक वेबसाइट-आधारित यूट्यूब वीडियो डाउनलोडर जो बिना किसी सॉफ्टवेयर को डाउनलोड या इंस्टॉल किए विंडोज, मैक या लिनक्स, एंड्रॉइड, आईफोन ... जैसे सभी प्लेटफॉर्म पर अच्छा काम करता है।"])},
  "Supports many formats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कई स्वरूपों का समर्थन करता है"])},
  "Our tool supports converting many video and audio formats. You can easily convert YouTube videos to 3GP, MP4, WMA, M4A, FLV, WEBM and MO formats, etc.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमारा टूल कई वीडियो और ऑडियो प्रारूपों को परिवर्तित करने का समर्थन करता है। आप आसानी से YouTube वीडियो को 3GP, MP4, WMA, M4A, FLV, WEBM और MO फॉर्मेट आदि में बदल सकते हैं।"])},
  "Free forever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमेशा के लिए आज़ाद"])},
  "The tool is 100% free and always will be. You can support us by sending and sharing this tool with your friends. Thank you!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टूल 100% मुफ़्त है और हमेशा रहेगा। आप इस टूल को अपने दोस्तों के साथ भेजकर और शेयर करके हमें सपोर्ट कर सकते हैं। आपको धन्यवाद!"])},
  "Online YouTube video downloader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑनलाइन यूट्यूब वीडियो डाउनलोडर"])},
  "Download Youtube videos easily with the \"Youtube Downloader\" tool. Support downloading and convert YouTube video with the best quality to formats MP4, 3GP, WEBM, M4A ... fast and completely free. This YouTube downloader application is compatible with all devices such as: computers, tablets and mobile phones ...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"यूट्यूब डाउनलोडर\" टूल से आसानी से यूट्यूब वीडियो डाउनलोड करें। डाउनलोड करने में सहायता करें और YouTube वीडियो को सर्वोत्तम गुणवत्ता के साथ MP4, 3GP, WEBM, M4A ... तेज़ और पूरी तरह से निःशुल्क स्वरूपित करें। यह YouTube डाउनलोडर एप्लिकेशन सभी उपकरणों के साथ संगत है जैसे: कंप्यूटर, टैबलेट और मोबाइल फोन ..."])},
  "The easiest YouTube video download guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सबसे आसान YouTube वीडियो डाउनलोड गाइड"])},
  "Paste the YouTube link or enter keywords in the search box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YouTube लिंक पेस्ट करें या खोज बॉक्स में कीवर्ड दर्ज करें"])},
  "Select the output format you want to transfer and click the \"Download\" button.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उस आउटपुट स्वरूप का चयन करें जिसे आप स्थानांतरित करना चाहते हैं और \"डाउनलोड\" बटन पर क्लिक करें।"])},
  "Wait a few seconds for the conversion to complete and download the file. Simple, easy to use.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रूपांतरण पूरा होने और फ़ाइल डाउनलोड करने के लिए कुछ सेकंड प्रतीक्षा करें। सरल, प्रयोग करने में आसान।"])},
  "Is youtube downloader have a limit of usage?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या यूट्यूब डाउनलोडर के उपयोग की सीमा है?"])},
  "Absolutely NO. Our tool allows you to convert and download in unlimited quantities and all for free.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिल्कुल नहीं। हमारा टूल आपको असीमित मात्रा में कनवर्ट करने और डाउनलोड करने की अनुमति देता है और सभी मुफ्त में।"])},
  "Can I download videos on mobile?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या मैं मोबाइल पर वीडियो डाउनलोड कर सकता हूं?"])},
  "Our tool works well on all devices such as PCs, smartphones and tablets.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमारा टूल पीसी, स्मार्टफोन और टैबलेट जैसे सभी उपकरणों पर अच्छा काम करता है।"])},
  "Where is the Youtube video stored after downloading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डाउनलोड करने के बाद Youtube वीडियो कहाँ स्टोर किया जाता है"])},
  "Please check the \"Downloads\" folder in your phone or the \"download history\" section of your browser.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृपया अपने फ़ोन में \"डाउनलोड\" फ़ोल्डर या अपने ब्राउज़र के \"डाउनलोड इतिहास\" अनुभाग की जाँच करें।"])},
  "Frequently Asked Questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अक्सर पूछे जाने वाले प्रश्नों"])},
  "What languages support ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कौन सी भाषाएं समर्थन करती हैं?"])},
  "We support download Youtube video in all popular languages today.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हम आज सभी लोकप्रिय भाषाओं में Youtube वीडियो डाउनलोड करने का समर्थन करते हैं।"])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["के बारे में"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेवा की शर्तें"])},
  "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गोपनीयता नीति"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपर्क"])}
}