..<template>
 <main id="main">
  <Header></Header>
  <Search></Search>
  <Footer></Footer> 

  </main>
</template>

<script>
import Header from '@/components/partials/Header'
import Footer from '@/components/partials/Footer'
import Search from '@/components/Search'
export default {
    name:"Home",
    components: {
    Header,
    Footer,
    Search,
    }
}
</script>

<style>

</style>

