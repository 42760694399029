export default {
  "Super Fast YouTube to MP3/MP4 Converter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YouTube videolarını ücretsiz indirin"])},
  "Best free tool to download videos from YouTube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YouTube'dan video indirmek için en iyi ücretsiz araç"])},
  "Search or paste Youtube link here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube bağlantısını buraya arayın veya yapıştırın"])},
  "Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlangıç"])},
  "Download Video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlangıç"])},
  "The best online YouTube video downloader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En iyi çevrimiçi YouTube video indiricisi"])},
  "Free tool to help you download video from Youtube to your computer for free. In addition, it supports converting to many different formats and qualities for download.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube'dan bilgisayarınıza ücretsiz olarak video indirmenize yardımcı olacak ücretsiz bir araç. Ayrıca, indirmek için birçok farklı biçime ve kaliteye dönüştürmeyi destekler."])},
  "Quick and easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çabuk ve kolay"])},
  "Our Youtube video downloader helps you quickly search and download any Youtube video to formats like: MP4, WEBM, M4A ...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube video indiricimiz, herhangi bir Youtube videosunu hızlıca aramanıza ve MP4, WEBM, M4A gibi formatlarda indirmenize yardımcı olur."])},
  "Unlimited YouTube downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sınırsız YouTube indirmesi"])},
  "Support downloading and converting unlimited Youtube videos with the best transfer speed up to 1GB/s.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 GB/sn'ye kadar en iyi aktarım hızıyla sınırsız Youtube videolarını indirmeyi ve dönüştürmeyi destekleyin."])},
  "Safety and Security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emniyet ve güvenlik"])},
  "The converted and downloaded files are kept the same quality and content as YouTube's.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dönüştürülen ve indirilen dosyalar, YouTube'unkilerle aynı kalitede ve içerikte tutulur."])},
  "Support all devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm cihazları destekleyin"])},
  "a website-based Youtube video downloader that works well on all platforms such as Windows, Mac or Linux, Android, iPhone ... without downloading or installing any software.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windows, Mac veya Linux, Android, iPhone gibi tüm platformlarda herhangi bir yazılım indirmeden veya yüklemeden sorunsuz çalışan web sitesi tabanlı bir Youtube video indiricisi."])},
  "Supports many formats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birçok formatı destekler"])},
  "Our tool supports converting many video and audio formats. You can easily convert YouTube videos to 3GP, MP4, WMA, M4A, FLV, WEBM and MO formats, etc.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aracımız birçok video ve ses formatını dönüştürmeyi destekler. YouTube videolarını kolayca 3GP, MP4, WMA, M4A, FLV, WEBM ve MO formatlarına vb. dönüştürebilirsiniz."])},
  "Free forever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sonsuza kadar ücretsiz"])},
  "The tool is 100% free and always will be. You can support us by sending and sharing this tool with your friends. Thank you!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Araç %100 ücretsizdir ve her zaman öyle kalacaktır. Bu aracı gönderip arkadaşlarınızla paylaşarak bize destek olabilirsiniz. Teşekkürler!"])},
  "Online YouTube video downloader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çevrimiçi YouTube video indiricisi"])},
  "Download Youtube videos easily with the \"Youtube Downloader\" tool. Support downloading and convert YouTube video with the best quality to formats MP4, 3GP, WEBM, M4A ... fast and completely free. This YouTube downloader application is compatible with all devices such as: computers, tablets and mobile phones ...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Youtube Downloader\" aracı ile Youtube videolarını kolayca indirin. YouTube videosunu en iyi kalitede indirmeyi destekleyin ve MP4, 3GP, WEBM, M4A formatlarına dönüştürün ... hızlı ve tamamen ücretsiz. Bu YouTube indirme uygulaması, bilgisayarlar, tabletler ve cep telefonları gibi tüm cihazlarla uyumludur ..."])},
  "The easiest YouTube video download guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En kolay YouTube video indirme kılavuzu"])},
  "Paste the YouTube link or enter keywords in the search box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YouTube bağlantısını yapıştırın veya arama kutusuna anahtar kelimeleri girin"])},
  "Select the output format you want to transfer and click the \"Download\" button.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktarmak istediğiniz çıktı biçimini seçin ve \"İndir\" düğmesini tıklayın."])},
  "Wait a few seconds for the conversion to complete and download the file. Simple, easy to use.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dönüştürmenin tamamlanması ve dosyayı indirmesi için birkaç saniye bekleyin. Basit, kullanımı kolay."])},
  "Is youtube downloader have a limit of usage?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube indiricisinin kullanım limiti var mı?"])},
  "Absolutely NO. Our tool allows you to convert and download in unlimited quantities and all for free.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kesinlikle hayır. Aracımız, sınırsız miktarda ve hepsini ücretsiz olarak dönüştürmenize ve indirmenize izin verir."])},
  "Can I download videos on mobile?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videoları mobilden indirebilir miyim?"])},
  "Our tool works well on all devices such as PCs, smartphones and tablets.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aracımız PC'ler, akıllı telefonlar ve tabletler gibi tüm cihazlarda iyi çalışır."])},
  "Where is the Youtube video stored after downloading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İndirdikten sonra Youtube videosu nerede saklanır?"])},
  "Please check the \"Downloads\" folder in your phone or the \"download history\" section of your browser.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen telefonunuzdaki \"İndirilenler\" klasörünü veya tarayıcınızın \"indirme geçmişi\" bölümünü kontrol edin."])},
  "Frequently Asked Questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıkça Sorulan Sorular"])},
  "What languages support ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangi dilleri destekler?"])},
  "We support download Youtube video in all popular languages today.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube videosunu bugün tüm popüler dillerde indirmeyi destekliyoruz."])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hakkında"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanım Şartları"])},
  "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gizlilik Politikası"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temas"])}
}