export default {
  "Super Fast YouTube to MP3/MP4 Converter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتحميل فيديوهات اليوتيوب مجانا"])},
  "Best free tool to download videos from YouTube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أفضل أداة مجانية لتحميل مقاطع الفيديو من اليوتيوب"])},
  "Search or paste Youtube link here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إبحث او قم بلصق رابط الفديو"])},
  "Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
  "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل"])},
  "Download Video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل الفيديو"])},
  "The best online YouTube video downloader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أفضل أداة لتنزيل الفديوهات من اليوتيوب عبر الإنترنت"])},
  "Free tool to help you download video from Youtube to your computer for free. In addition, it supports converting to many different formats and qualities for download.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أداة مجانية لمساعدتك في تنزيل الفيديو من اليوتيوب إلى جهاز الكمبيوتر الخاص بك مجانًا. بالإضافة إلى أنها تدعم التحويل إلى العديد من التنسيقات والصفات المختلفة للتنزيل."])},
  "Quick and easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سريع وسهل"])},
  "Our Youtube video downloader helps you quickly search and download any Youtube video to formats like: MP4, WEBM, M4A ...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يساعدك برنامج تنزيل الفيديو على  اليوتيوب  على البحث بسرعة وتنزيل أي فيديو على اليوتيوب بصيغ متعددة"])},
  "Unlimited YouTube downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميلات من اليوتيوب غير محدودة"])},
  "Support downloading and converting unlimited Youtube videos with the best transfer speed up to 1GB/s.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعم تنزيل وتحويل عدد غير محدود من مقاطع فيديو اليوتيوب مع أفضل سرعة نقل تصل إلى 1 جيجابايت / ثانية."])},
  "Safety and Security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السلامة والأمن"])},
  "The converted and downloaded files are kept the same quality and content as YouTube's.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم الاحتفاظ بالملفات المحولة والمنزلة بنفس جودة ومحتوى اليوتيوب"])},
  "Support all devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يدعم جميع الاجهزة"])},
  "a website-based Youtube video downloader that works well on all platforms such as Windows, Mac or Linux, Android, iPhone ... without downloading or installing any software.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برنامج تنزيل فيديو اليوتيوب قائم على موقع الويب ويعمل جيدًا على جميع الأنظمة الأساسية مثل الوندوز أو الماك أو الينكس أو الأندرويد أو الأيفون ... دون تنزيل أو تثبيت أي برنامج"])},
  "Supports many formats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يدعم العديد من الصيغ"])},
  "Our tool supports converting many video and audio formats. You can easily convert YouTube videos to 3GP, MP4, WMA, M4A, FLV, WEBM and MO formats, etc.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تدعم أداتنا تحويل العديد من تنسيقات الفيديو والصوت. يمكنك بسهولة تحويل مقاطع فيديو YouTube إلى تنسيقات 3GP و MP4 و WMA و M4A و FLV و WEBM و MO ، إلخ."])},
  "Free forever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجاني بشكل كامل"])},
  "The tool is 100% free and always will be. You can support us by sending and sharing this tool with your friends. Thank you!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأداة مجانية 100٪ وستظل كذلك دائمًا. يمكنك دعمنا بإرسال ومشاركة هذه الأداة مع أصدقائك. شكرا لك!"])},
  "Online YouTube video downloader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل فيديو من يوتيوب"])},
  "Download Youtube videos easily with the \"Youtube Downloader\" tool. Support downloading and convert YouTube video with the best quality to formats MP4, 3GP, WEBM, M4A ... fast and completely free. This YouTube downloader application is compatible with all devices such as: computers, tablets and mobile phones ...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتنزيل مقاطع فيديو Youtube بسهولة باستخدام أداة \"Youtube Downloader \". دعم تنزيل وتحويل فيديو YouTube بأفضل جودة إلى تنسيقات MP4 و 3GP و WEBM و M4A ... سريع ومجاني تمامًا. يتوافق تطبيق YouTube downloader هذا مع جميع الأجهزة مثل: أجهزة الكمبيوتر والأجهزة اللوحية والهواتف المحمولة ..."])},
  "The easiest YouTube video download guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسهل دليل لتحميل فيديوهات اليوتيوب"])},
  "Paste the YouTube link or enter keywords in the search box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصق رابط YouTube أو أدخل الكلمات الأساسية في مربع البحث"])},
  "Select the output format you want to transfer and click the \"Download\" button.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد صيغة  التحميل الذي تريد نقله وانقر فوق الزر تنزيل."])},
  "Wait a few seconds for the conversion to complete and download the file. Simple, easy to use.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتظر بضع ثوان حتى يكتمل التحويل وقم بتنزيل الملف. بسيط وسهل الاستخدام"])},
  "Is youtube downloader have a limit of usage?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل هذه الأداة لديها حد للاستخدام؟"])},
  "Absolutely NO. Our tool allows you to convert and download in unlimited quantities and all for free.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طبعا لأ. تسمح لك أداتنا بالتحويل والتنزيل بكميات غير محدودة وكل ذلك مجانًا."])},
  "Can I download videos on mobile?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يمكنني تحميل مقاطع الفيديو على الهاتف المحمول؟"])},
  "Our tool works well on all devices such as PCs, smartphones and tablets.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعمل أداتنا بشكل جيد على جميع الأجهزة مثل أجهزة الكمبيوتر والهواتف الذكية والأجهزة اللوحية."])},
  "Where is the Youtube video stored after downloading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أين يتم تخزين فيديو Youtube بعد التحميل"])},
  "Please check the \"Downloads\" folder in your phone or the \"download history\" section of your browser.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى التحقق من مجلد التحميلات  في هاتفك أو قسم  سجل التحميلات  في متصفحك"])},
  "Frequently Asked Questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسئلة الأكثر شيوعا"])},
  "What languages support ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هي اللغات التي تدعم؟"])},
  "We support download Youtube video in all popular languages today.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن ندعم تنزيل فيديو Youtube بجميع اللغات الشائعة اليوم."])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
  "Terms of Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الإستخدام"])},
  "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الخصوصية"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إتصل بنا"])}
}