import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import VueSocialSharing from 'vue-social-sharing'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'


const app = createApp(App)


app.use(i18n);
app.use(router);
app.use(VueSocialSharing);

 // set global variables
 require('./helpers/constants')(app)

 app.mount('#app')
