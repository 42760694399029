<template>
  <Header></Header>
  <div class="container container-main mt-3">
    <div class="ftco-section">
        <h1>About us</h1>
        <p>
            In the stage of developing and applying information technology in life to help work or entertainment purposes is very normal, and among them YouTube is largest social networks. Now born to serve the needs
            of human entertainment.
        </p>
        <p><b> YouTube </b> is the most popular video sharing social network today, users can upload and share video clips from phone or computer very easily.</p>
        <p>
            So what is <b> what is  {{WEBSITE_NAME}}? </b> in relation to YouTube. In fact,  {{WEBSITE_NAME}} is a tool that allows you to download and convert any video on Youtube online platforms without having to install any other
            software.
        </p>
        <p>Using  {{WEBSITE_NAME}} is very simple, with just a few taps you can download your desired video at 1GB/s connection speed and completely free</p>
        <p>
             {{WEBSITE_NAME}} tool allows downloading and converting Youtube, to the most popular formats such as MP4, MP4, WEBM, M4A, 3GB with video output quality options of 4320p, 3072p, 2880p, 2160p, 1440p, 1080p. , 720p, 480p, 360p,
            240p, 144p and sound quality 320kbps, 256kbps, 192kbps, 128kbps, 64kbps.
        </p>
        <p>
             {{WEBSITE_NAME}} is a completely free tool, we do not charge any user costs nor limit the number of times to download the video or the quality of the video. Therefore, if you find this tool useful, please help us send it and recommend it to
            friends and relatives to use it. That is the driving force that helps us maintain and develop a better and Youtube video downloader.
        </p>
        <p>Thank you very much</p>
    </div>
</div>

  <Footer></Footer>

</template>

<script>
import Header from '@/components/partials/Header'
import Footer from '@/components/partials/Footer'

export default {
  name:"About",
    components: {
    Header,
    Footer,
  }
}
</script>

<style>

</style>