<template>
 <main>
  <Header></Header>
  <div class="container container-main ftco-section">
      <h1 class="text-center">{{$t('Super Fast YouTube to MP3/MP4 Converter')}}</h1>
      <p class="text-center">{{$t('Best free tool to download videos from YouTube')}}</p>
      <div  v-if="loaded" class="d-flex justify-content-center my-4">
      <div   class="spinner-border text-primary"></div>
      </div>
      <div  v-if="!loaded" class="row my-4 mx-1">

    <div class="col-md-5 p-3 bg-light">
        <div>
        <iframe  v-if="info.videoDetails"   allowfullscreen="true" style="background: url('./assets/img/spinner-3.gif');background-repeat:no-repeat;background-position: center center" 
          width="100%" height="300px"
           :src="'https://www.youtube.com/embed/'+info.videoDetails.videoId" ></iframe>
    </div>
    </div>
    <div class="col-md-7 bg-light p-4">
     <h2 v-if="info.videoDetails">{{info.videoDetails.title}}</h2>
    <div class="d-flex mt-4">
        <!-- <select  ref="selected_video" class="form-control form-control-small" id="formatSelect">
            <optgroup v-for="format in  Object.keys(formats)" v-bind:key="format" :label="format">
                    <option v-for="ext in  formats[format] " :url="ext.url" :filename="`${info.videoDetails.videoId}_${ext.itag}.${format}`"  v-bind:key="ext.itag" >{{`${format} ${helper.getLabelQuality(ext.qualityLabel,ext.quality)}  ${helper.niceBytes(ext.contentLength)}`}}</option>
            </optgroup>
       </select> -->
       <select @change="getFormats()" v-model="selectedFormat" class="form-control form-control-small">
         <option value="videos" >Videos</option>
         <option value="mp3">MP3</option>
         <option value="merged">Others</option>
       </select>

    <!-- <button @click="dowbloadVideo()" v-if="true" type="button" class="btn btn-success w-100 mx-1">
        {{$t('Download')}}
    </button> -->
    </div>
     <div  v-if="!formats.length" class="d-flex justify-content-center my-4">
      <div   class="spinner-border text-primary"></div>
    </div>
    <div v-if="formats.length" class="d-flex flex-wrap mt-4 border bg-white p-1">
      <div v-for="format in formats" v-bind:key="format" class="p-2">
       <a v-if="format.size.length" @click="downloadFormat(format)" 
       class="p-2 d-block shadow-sm rouned text-white bg-success mx-1 pointer"> 
        {{format.format +' '+format.quality+' '+format.size}}</a>
    </div>
    </div>

   <div v-if="info && info.videoDetails" class="mt-4 bg-white">
     <p class="p-2 border" v-if="info.videoDetails.description">{{info.videoDetails.description.slice(0,300)+ ' ...'}}</p>

   </div>
    <div id="social-media">
       <ShareNetwork v-for="network in helper.networks" v-bind:key="network.name"
    :network="network.name"
    :url="shareUrl"
    :title="info.videoDetails.title"
    :description="info.videoDetails.title"
    >
  <i :class="network.icon" aria-hidden="true"></i>
  </ShareNetwork>
    </div>

    </div>
    </div>
    <Content></Content>
    </div>
 

  <Footer></Footer>
  </main>
</template>
<script>
import Header from '@/components/partials/Header'
import Footer from '@/components/partials/Footer'
import Content from "./Content"; 
import Helper from "@/helpers/helper"
const axios = require('axios')
const API_URL = location.origin +'/'


export default {
  name: 'Download',
  data() {
      return {
        API_URL:'',
        shareUrl:'',
        video: {},
        loaded : true,
        formats : [],
        info : {},
        helper:Helper,
        selectedFormat:'videos',
        params:{}
      }
  },
  components:{Header,Footer,Content},
  methods: {
  getFormats(){
    const id = this.params.id;
    const endPoint = this.API_URL+`get-formats/${this.selectedFormat}/${id}`;
    this.formats = [];
    axios.get(endPoint).then(res=>{
      if(res.status == 200 && res.data[this.selectedFormat])
      {
        this.formats = res.data[this.selectedFormat]
        Helper.log(this.formats)
      }else{
        this.formats = [];
      }
    })
  },
  getRightFormat(format)
  {
    const formats = ['mp4','3gp','mp3','webm','mkv'];
    let subText = format.text.trim().slice(0,3).toLowerCase();
    if(formats.includes(subText)) return subText;
     subText = format.text.trim().slice(0,4).toLowerCase();
    if(formats.includes(subText.toLowerCase())) return subText;
    return 'file';
  },
  downloadFormat(format){
   const ext = format.format;
   const url = btoa(encodeURIComponent(format.link)); 
   const videoId = this.info.videoDetails.videoId;
   const fileName = this.info.videoDetails.title;
   const downladUrl =`${this.API_URL}download-format?ext=${ext}&videoId=${videoId}&url=${url}&fileName=${fileName}`;
   open(downladUrl,'_blank')
  },
  getVideoInfo(){
     const id = this.params.id;
     axios.get(this.API_URL + 'get-video-info/' + id).then(res => {
       if(res)
       {
         this.formats = res.data.formats;
         this.info = res.data.info;
         this.loaded = false;
         const redirect = encodeURIComponent(window.location.href);
         this.shareUrl =`${this.API_URL}share-video/${id}?redirect=${redirect}`;
         this.getFormats()
       }
         })
  },

  },
  mounted() {
  this.API_URL = this.ENVIRONNEMENT === "prod" ? API_URL : this.API_ROOT_DEV
  this.params = this.$route.params;
  if(this.params.id)
  {
    this.getVideoInfo()
  }
  },
}
</script>


<style>
  .pointer {
    cursor:pointer;
  }
</style>

