
export default  {
    log(message)
    {
      if(process.env.VUE_APP_CONTACT_LINK != "prod")
        console.log(message);
    },
    niceBytes(x){
        if(x)
        {
          const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
          let l = 0, n = parseInt(x, 10) || 0;
          while(n >= 1024 && ++l){
              n = n/1024;
          }
          return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
        }
        return '';
      },
      isValidLink(string) {
        let url;
        
        try {
          url = new URL(string);
        } catch (_) {
          return false;  
        }
      
        return url.protocol === "http:" || url.protocol === "https:";
      },
      getLabelQuality(label,quality)
      {
        const _label = label ? label : '';
        const _quality = quality ? quality : '';
        return `${_label} ${_quality}`;
      },
     networks : [
      {
      name:"facebook",
      icon:"fab fa-facebook"
      },
      {
      name:"twitter",
      icon:"fab fa-twitter"
      }, 
      {
      name:"whatsApp",
      icon:"fa-brands fa-whatsapp"
      },
      {
      name:"tumblr",
      icon:"fab fa-tumblr"
      },
      // {
      // name:"messenger",
      // icon:"fab fa-facebook-messenger"
      // },
      {
      name:"reddit",
      icon:"fab fa-reddit"
      },
       {
      name:"telegram",
      icon:"fab fa-telegram"
      },
      {
      name:"pinterest",
      icon:"fab fa-pinterest"
      },
      // {
      // name:"linkedin",
      // icon:"fab fa-linkedin"
      // },
      {
      name:"Email",
      icon:"fas fa-envelope"
      }
  ]
  

}