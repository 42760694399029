<template>
 <div  @click="getDownloadPage(video)" >
   <div  class="item w-100 border mb-3 shadow-sm position-relative">
    <img  :src="video.thumbnails && video.thumbnails.length ? video.thumbnails[video.thumbnails.length - 1]['url'] : '#'" class="w-100">
     <div    class="mt-1 d-flex justify-content-between">
       <h5  :class="{'rtl':$i18n.locale == 'ar'}" class="text-left p-2" >{{video.title}}</h5>
     </div>
     <div class="btn-download-item ">
     <button type="button" class="btn btn-success">
       <i class="fa fa-download mx-1 text-white" aria-hidden="true"></i>
       {{$t('Download Video')}}
       </button>
     </div>
   </div>
 </div>
</template>

<script>
const API_URL = location.origin +'/'

export default {
  name: 'VideoElement',
  data() {
    return {
      selectedVideo: {},
      loaded : false,
      info : {},
      API_URL: ''

    }
  },
  computed:{
  },
   props: {
    video: Object
  },
  mounted(){
   this.API_URL = this.ENVIRONNEMENT === "prod" ? API_URL : this.API_ROOT_DEV
  },
  methods: {

  getDownloadPage(video){
    scrollTo(0,0);
    this.selectedVideo = video;
    this.$router.push({name:'download',params:{lang:this.$i18n.locale,id:video.id}});
  }
 
  }

}
</script>

<style lang="scss" scoped>
 .item{
   height: 300px;
   overflow:hidden;
   cursor:pointer;
 }
</style>